<template>
  <div class="py-1">
    <b-card no-body>
      <b-card-header>
        <div>
          <h4 class="font-weight-bolder text-colorBlack">Lecture Title</h4>
        </div>
      </b-card-header>
      <b-card-body>
        <hr />
        <b-form>
          <span class="font-weight-bolder text-colorBlack">
            Lecture Transcript</span
          >
          <b-form-group class="mt-2">
            <quill-editor v-model="content" :options="option" />
          </b-form-group>
        </b-form>
      </b-card-body>
      <b-card-footer footer-class="d-flex justify-content-end">
        <div>
          <b-button
            type="submit"
            variant="colorLightBlue"
            rounded
            class="mr-1"
            @click="
              () => {
                this.$router.push({ name: 'InstructorLectureCreate' });
              }
            "
          >
            <span class="text-colorBlue">Cancel</span>
          </b-button>
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            @click="
              () => {
                this.$router.push({ name: 'InstructorLecture' });
              }
            "
          >
            <span class="text-colorWhite">Publish</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      content: "",
      option: {
        subjects: ["Math", "Science", "History", "English"],
      },
      addLessonContent: false,
      includeRealLife: false,
      includeCaseStudies: false,
      addExerciseContent: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
</style>
